var query = {
    allCampaigns: `
        query campaigns($limit: Float
            $page: Float
            $_id: ID
            $name: String
            $active: Boolean
            $companyId: ID) {
            campaigns(limit: $limit
                page: $page
                _id: $_id
                name: $name
                active: $active
                companyId: $companyId) {
                    _id,
                    name,
                    type,
                    moneyPerEngagementReal,
                    moneyPerEngagementExpected,
                    engagementReal,
                    engagementExpected,
                    influencersExpected,
                    influencersTotal,
                    engagementVelocityReal,
                    engagementVelocityExpected,
                    forecastDays,
                    investment,
                    stock,
                    customPricesEnabled,
                    customAdPrice,
                    customInsightMultiplier,
                    budgetSpent,
                    budgetFreezed,
                    budgetAvailable,
                    engagementFacebook,
                    engagementInstagram,
                    engagementTwitter,
                    tagCount,
                    likeCount,
                    sharedCount,
                    linkCount,
                    printCount,
                    mentionCount,
                    hashtagCount,
                    commentCount,
                    engagementNotSponsored,
                    engagementMaleInfluencer,
                    engagementFemaleInfluencer,
                    engagementMaleInfluenced,
                    engagementFemaleInfluenced,
                    engagementAnonymousInfluenced,
                    _AdvertisementsWaitingForPlatformAudit {
                      _id
                    },
                    _AdvertisementsWaitingForCustomerAudit {
                      _id
                    },
                    status
            }
        }
        `,
    allCampaignsName: `
        query campaigns($name: String 
                        $active: Boolean
                        $companyId: ID
                        $page: Float
                        $limit: Float) 
                        {
                    campaigns(name: $name
                        active: $active
                        companyId: $companyId
                        page: $page
                        limit: $limit) {
                                name
                                }
            }
        `,
    campaign: `
        query campaigns($_id: ID) {
            campaigns(_id: $_id){
                _id,
                name,
                creationDt,
                startDt,
                endDt,
                active,
                status,
                investment,
                stock,
                customPricesEnabled,
                customAdPrice,
                customInsightMultiplier,
                budgetAvailable,
                budgetFreezed,
                budgetSpent,
                moneyPerEngagementExpected,
                moneyPerEngagementReal,
                engagementReal,
                engagementExpected,
                influencersExpected,
                influencersTotal,
                engagementVelocityReal,
                engagementVelocityExpected,
                forecastDays,
                tagCount,
                likeCount,
                sharedCount,
                linkCount,
                printCount,
                mentionCount,
                hashtagCount,
                commentCount,
                engagementNotSponsored,
                engagementMaleInfluencer,
                engagementFemaleInfluencer,
                engagementMaleInfluenced,
                engagementFemaleInfluenced,
                engagementAnonymousInfluenced,
                facebookAnonymousInfluencedCount,
                twitterAnonymousInfluencedCount,
                instagramAnonymousInfluencedCount,
                influencersScope,
                facebookLikeCount,
                facebookLinkCount,
                facebookTagCount,
                facebookSharedCount,
                facebookMentionCount,
                facebookHashtagCount,
                facebookPrintCount,
                facebookCommentCount,
                twitterLikeCount,
                twitterLinkCount,
                twitterTagCount,
                twitterSharedCount,
                twitterMentionCount,
                twitterHashtagCount,
                twitterPrintCount,
                twitterCommentCount,
                instagramLikeCount,
                instagramLinkCount,
                instagramTagCount,
                instagramSharedCount,
                instagramMentionCount,
                instagramHashtagCount,
                instagramPrintCount,
                instagramCommentCount,
                facebookMaleInfluencerCount,
                facebookFemaleInfluencerCount,
                facebookMaleInfluencedCount,
                facebookFemaleInfluencedCount,
                twitterMaleInfluencerCount,
                twitterFemaleInfluencerCount,
                twitterMaleInfluencedCount,
                twitterFemaleInfluencedCount,
                instagramMaleInfluencerCount,
                instagramFemaleInfluencerCount,
                instagramMaleInfluencedCount,
                instagramFemaleInfluencedCount,
                influencersTotalHistoric {
                    timespan
                    value
                },
                engagementRealHistoric {
                    timespan
                    value
                },
                facebookInfluencedsCardinal {
                  type
                  coordinates
                },
    						facebookInfluencersCardinal {
    						  type
                  coordinates
    						},
                twitterInfluencersCardinal {
                  type
                  coordinates
                },
                instagramInfluencersCardinal {
                  type
                  coordinates
                },
                twitterInfluencedsCardinal {
                  type
                  coordinates
                },
                instagramInfluencedsCardinal {
                  type
                  coordinates
                }
            }
        }
    `,
    campaignForUpdate: `
        query campaigns($_id: ID) {
            campaigns(_id: $_id){
                _id,
                name,
                status,
                nextStatuses,
                followers,
                type,
                influencePeopleAs,
                adsPerIR,
                brief,
                paymentType,
                productPaymentDescription,
                paymentPerEach,
                investment,
                stock,
                customPricesEnabled,
                customAdPrice,
                customInsightMultiplier,
                categoryCriterias {
                  _id,
                  name
                },
                regionCriterias {
                  _id,
                  country,
                  state,
                  city,
                },
                sexCriterias {
                  _id,
                  name
                },
                customerStarCriterias {
                  _id,
                  stars
                },
                platformStarCriterias {
                  _id,
                  stars
                },
                ageCriterias {
                  _id,
                  age
                },
                specificTargetScope {
                  _id,
                  firstName,
                  lastName,
                  key,
                  sponsorshipEffectivenessReference {
                    _id
                    framePrice
                    hashtagPrice
                    webSitePrice
                    tagMentionMePrice
                    sealPrice
                    tagMentionPeoplePrice
                    productUsagePrice
                    insightAveragePrice
                    freezedBudget
                    insightPriceFactor
                    totalPrice
                    from
                    to
                    AdPrice
                  }
                  advertisingEffectivenessReference {
                    _id
                    from
                    to
                    AdPrice
                    framePrice
                    hashtagPrice
                    webSitePrice
                    tagMentionMePrice
                    sealPrice
                    tagMentionPeoplePrice
                    productUsagePrice
                    insightAveragePrice
                    freezedBudget
                    insightPriceFactor
                    totalPrice
                  },
                  spotEffectivenessReference {
                    _id
                    freezedBudget
                    insightPriceFactor
                    totalPrice
                    AdPrice
                    from
                    to
                  }
                },
                sponsorshipEffectivenessCriterias {
                  _id
                  from
                  to
                  AdPrice
                  framePrice
                  hashtagPrice
                  webSitePrice
                  tagMentionMePrice
                  sealPrice
                  tagMentionPeoplePrice
                  productUsagePrice
                  insightAveragePrice
                  freezedBudget
                  insightPriceFactor
                  totalPrice
                },
                advertisingEffectivenessCriterias {
                  _id
                  from
                  to
                  AdPrice
                  framePrice
                  hashtagPrice
                  webSitePrice
                  tagMentionMePrice
                  sealPrice
                  tagMentionPeoplePrice
                  productUsagePrice
                  insightAveragePrice
                  freezedBudget
                  insightPriceFactor
                  totalPrice
                },
                spotEffectivenessCriterias {
                  _id
                  from
                  to
                  AdPrice
                  freezedBudget
                  insightPriceFactor
                  totalPrice
                },
                specificTarget
                creationDt
                startDt
                endDt
                resources {
                    frame {
                      enabled
                    },
                    hashtag {
                      enabled
                      value
                    },
                    webSite {
                      enabled
                      value
                    }
                    tagMentionMe {
                      enabled,
                      facebook {
                        enabled
                        value
                      }
                      instagram{
                        enabled
                        value
                      }
                      twitter {
                        enabled
                        value
                      }
                    }
                    seal {
                      enabled
                      value
                    },
                    tagMentionPeople {
                      enabled
                    },
                    productUsage {
                      enabled
                    },
                    productUsageOficial {
                      enabled
                      value
                    },
                    spot {
                      imageEnabled
                      image
                      description
                      videoEnabled
                      video
                    }
                }
            }
        }
    `,
    campaignHeaderAudit: `
    query campaigns($_id: ID) {
      campaigns(_id: $_id){
          _id,
          name,
          type,
          brief,
          resources {
            frame {
            enabled,
          	},
            hashtag {
              enabled,
              value
            },
            webSite {
              enabled,
              value
            },
            tagMentionMe {
              enabled,
              facebook {
                enabled,
                value
              },
              instagram {
                enabled,
                value
              },
              twitter {
                enabled,
                value
              }
            },
            seal {
              enabled,
              value
            },
            tagMentionPeople {
              enabled,
            },
            productUsage {
              enabled
            },
            productUsageOficial {
              enabled,
              value
            }
          }
        }
      }
    `
}

export default {
    query
};