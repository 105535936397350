import {
  call,
  put,
  takeLatest,
  take,
  fork,
  cancel,
  select,
  cancelled
} from "redux-saga/effects";
import { delay, eventChannel } from "redux-saga";
import {
  subscriptionScopeSucess,
  subscriptionOn
} from "../actions/campaignCreationSubscription";
import { SUBSCRIPTION_SCOPE, SUBSCRIPTION_SCOPE_CLOSE } from "../actionTypes";
import { SubscriptionClient } from "subscriptions-transport-ws";
var client = null;

const sessionToken = state => state.login.token;
// const api_host = state => state.basics.api.host;
// const api_port = state => state.basics.api.port;
const api_domain = state => state.basics.api.api_domain;

function* websocketInitChannel(vars) {
  const token = yield select(sessionToken);
//   const host = yield select(api_host);
//   const port = yield select(api_port);
  const domain = yield select(api_domain);
  client = new SubscriptionClient(`ws://${domain}/subscriptions`, {
    reconnect: true,
    // lazy: true,
    // inactivityTimeout: 600000,
    connectionParams: {
      headers: {
        // 'content-type': 'application/json', // these are headers
        // 'x-hasura-admin-secret': 'SUPERPASSWORD',
        "x-token": token
      }
    }
  });
  return eventChannel(emitter => {
    client.onConnected(() => {
      client
        .request({
          query: `subscription campaign(
                        $_id: ID!
                        $observedFields: [CampaignObservablefields]!
                    ) 
                    { campaign (
                        _id:$_id, 
                        observedFields: $observedFields
                    ){
                        success,
                        data
                    }
                    }`,
          operationName: "campaign",
          variables: {
            _id: vars._id,
            observedFields: vars.observedFields
          }
        })
        .subscribe({
          next: result => {
            emitter({ data: result.data.campaign.data });
          }
        });
    });

    return () => {
      emitter("hola");
      clearInterval("hola");
    };
  });
}

function* dispatchAction(payload) {
  try {
    yield call(delay, 2000);
    yield put(subscriptionScopeSucess(payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

const subscriptionScope = action =>
  fork(function*() {
    let lastTask;
    if (client != null) yield put(subscriptionOn());
    let step1 = yield websocketInitChannel(action.payload);
    while (true) {
      const step2 = yield take(step1);
      if (lastTask != null) {
        yield cancel(lastTask); // cancel is no-op if the task has already terminated
      }
      //yield sleep(2000);

      // const influencersScopeCount = yield select(getInfluencersScopeCount);
      // console.log('desde la saga', influencersScopeCount);

      lastTask = yield fork(dispatchAction, { data: JSON.parse(step2.data) });
    }
  });

// eslint-disable-next-line require-yield
function* subscriptionScopeClose(action) {
  if (client !== null) {
    client.unsubscribe();
    client.close();
    client = null;
  }
}

export default function* campaignCreationSubscription() {
  yield takeLatest(SUBSCRIPTION_SCOPE, subscriptionScope);
  yield takeLatest(SUBSCRIPTION_SCOPE_CLOSE, subscriptionScopeClose);
}
