import { put, takeLatest, take, select } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import { subscriptionCampaignDataSuccess } from "../actions/campaigns";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { SUBSCRIPTION_CAMPAIGN_DATA } from "../actionTypes";

// const api_host = state => state.basics.api.host;
// const api_port = state => state.basics.api.port;
const api_domain = state => state.basics.api.api_domain;

var client = null;
const sessionToken = state => state.login.token;

function* websocketInitChannel() {
  const token = yield select(sessionToken);
//   const host = yield select(api_host);
//   const port = yield select(api_port);
  const domain = yield select(api_domain);
  client = new SubscriptionClient(`ws://${domain}/subscriptions`, {
    reconnect: true,
    // lazy: true,
    // inactivityTimeout: 600000,
    connectionParams: {
      headers: {
        // 'content-type': 'application/json', // these are headers
        // 'x-hasura-admin-secret': 'SUPERPASSWORD',
        "x-token": token
      }
    }
  });
  return eventChannel(emitter => {
    client.onConnected(() => {
      client
        .request({
          query: `subscription useInfo {
                campaign(_id:"5bfd4883b7891f3924375e52", observedField: influencersScope){
                    success,
                    data
                }
                }`,
          operationName: "useInfo",
          variables: {
            id: 3
          }
        })
        .subscribe({
          next: result => {
            emitter({ data: result.data.campaign.data });
          }
        });
    });

    return () => {
      emitter("hola");
      clearInterval("hola");
    };
  });
}

function* subscriptionCampaignData(action) {
  try {
    while (true) {
      let res = yield websocketInitChannel();
      var res2 = yield take(res);
      yield put(subscriptionCampaignDataSuccess({ res2 }));
    }
  } finally {
  }
}

// function* prueba (result) {
//   yield put(subscriptionCampaignDataSuccess({data:result.data.campaign.data}));
// }

export default function* companiesSaga() {
  yield takeLatest(SUBSCRIPTION_CAMPAIGN_DATA, subscriptionCampaignData);
}
