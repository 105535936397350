import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import wizardStyle from "assets/jss/material-dashboard-pro-react/components/wizardStyle.jsx";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import 'react-circular-progressbar/dist/styles.css';
import InfluencersScopeValue from './influencers';
import InfluencedsScopeValue from './influenceds';
import InvestmentRequired from './investmentRequired';
import AutomaticAdjustmentInvestment from './adjustInvestmentRequired';

const theme = ({
  fullWidth50: {
    width: "50%"
  },
  alignCenter: {
    'text-align': 'center',
  },
  ...wizardStyle
})

const percentage = 66;
 

class Wizard extends React.Component {
  constructor(props) {
    super(props);
    var width;
    if (this.props.steps.length === 1) {
      width = "100%";
    } else {
      if (window.innerWidth < 600) {
        if (this.props.steps.length !== 3) {
          width = "50%";
        } else {
          width = 100 / 3 + "%";
        }
      } else {
        if (this.props.steps.length === 2) {
          width = "50%";
        } else {
          width = 100 / 3 + "%";
        }
      }
    }
    this.state = {
      currentStep: 0,
      items: 0,
      color: this.props.color,
      nextButton: this.props.steps.length > 1 ? true : false,
      previousButton: false,
      finishButton: this.props.steps.length === 1 ? true : false,
      width: width,
      movingTabStyle: {
        transition: "transform 0s"
      },
      allStates: []
    };
    this.navigationStepChange = this.navigationStepChange.bind(this);
    this.refreshAnimation = this.refreshAnimation.bind(this);
    this.previousButtonClick = this.previousButtonClick.bind(this);
    this.previousButtonClick = this.previousButtonClick.bind(this);
    this.finishButtonClick = this.finishButtonClick.bind(this);
    this.updateWidth = this.updateWidth.bind(this);
  }
  componentDidMount() {
    this.refreshAnimation(0);
    window.addEventListener("resize", this.updateWidth);
    this.setState({ 
      items: this.props.steps.length
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWidth);
  }

  updateWidth() {
    this.refreshAnimation(this.state.currentStep);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.steps.length !== nextState.items && nextState.items !== 0) {
      var key = this.state.currentStep;
      this.setState({ 
        items: this.props.steps.length,
        nextButton: this.props.steps.length > key + 1 ? true : false,
        previousButton: key > 0 ? true : false,
        finishButton: this.props.steps.length === key + 1 ? true : false
      });
      this.refreshAnimation(this.state.currentStep);
    }

    return true;
    
  }
  navigationStepChange(key) {
    if (this.props.steps) {
      var validationState = true;
      if (key > this.state.currentStep) {
        for (var i = this.state.currentStep; i < key; i++) {
          if (this[this.props.steps[i].stepId].sendState !== undefined) {
            this.setState({
              allStates: [
                ...this.state.allStates,
                {
                  [this.props.steps[i].stepId]: this[
                    this.props.steps[i].stepId
                  ].sendState()
                }
              ]
            });
          }
          if (
            this[this.props.steps[i].stepId].isValidated !== undefined &&
            this[this.props.steps[i].stepId].isValidated() === false
          ) {
            validationState = false;
            break;
          }
        }
      }
      if (validationState) {
        this.setState({
          currentStep: key,
          nextButton: this.props.steps.length > key + 1 ? true : false,
          previousButton: key > 0 ? true : false,
          finishButton: this.props.steps.length === key + 1 ? true : false
        });
        this.refreshAnimation(key);
      }
    }
  }
  nextButtonClick() {
    if (
      (this.props.validate &&
        ((this[this.props.steps[this.state.currentStep].stepId].isValidated !==
          undefined &&
          this[
            this.props.steps[this.state.currentStep].stepId
          ].isValidated()) ||
          this[this.props.steps[this.state.currentStep].stepId].isValidated ===
            undefined)) ||
      this.props.validate === undefined
    ) {
      if (
        this[this.props.steps[this.state.currentStep].stepId].sendState !==
        undefined
      ) {
        this.setState({
          allStates: [
            ...this.state.allStates,
            {
              [this.props.steps[this.state.currentStep].stepId]: this[
                this.props.steps[this.state.currentStep].stepId
              ].sendState()
            }
          ]
        });
      }
      var key = this.state.currentStep + 1;
      this.setState({
        currentStep: key,
        nextButton: this.props.steps.length > key + 1 ? true : false,
        previousButton: key > 0 ? true : false,
        finishButton: this.props.steps.length === key + 1 ? true : false
      });
      this.refreshAnimation(key);
    }
  }
  previousButtonClick() {
    if (
      this[this.props.steps[this.state.currentStep].stepId].sendState !==
      undefined
    ) {
      this.setState({
        allStates: [
          ...this.state.allStates,
          {
            [this.props.steps[this.state.currentStep].stepId]: this[
              this.props.steps[this.state.currentStep].stepId
            ].sendState()
          }
        ]
      });
    }
    var key = this.state.currentStep - 1;
    if (key >= 0) {
      this.setState({
        currentStep: key,
        nextButton: this.props.steps.length > key + 1 ? true : false,
        previousButton: key > 0 ? true : false,
        finishButton: this.props.steps.length === key + 1 ? true : false
      });
      this.refreshAnimation(key);
    }
  }
  finishButtonClick() {
    if (
      this.props.validate &&
      ((this[this.props.steps[this.state.currentStep].stepId].isValidated !==
        undefined &&
        this[this.props.steps[this.state.currentStep].stepId].isValidated()) ||
        this[this.props.steps[this.state.currentStep].stepId].isValidated ===
          undefined) &&
      this.props.finishButtonClick !== undefined
    ) {
      this.props.finishButtonClick();
    }
  }

  refreshAnimation(index) {
    var total = this.props.steps.length;
    var li_width = 100 / total;
    var total_steps = this.props.steps.length;
    var move_distance = this.refs.wizard.children[0].offsetWidth / total_steps;
    var index_temp = index;
    var vertical_level = 0;

    var mobile_device = window.innerWidth < 600 && total > 3;

    if (mobile_device) {
      move_distance = this.refs.wizard.children[0].offsetWidth / 2;
      index_temp = index % 2;
      li_width = 50;
    }

    this.setState({ width: li_width + "%" });

    var step_width = move_distance;
    move_distance = move_distance * index_temp;

    var current = index + 1;

    if (current === 1 || (mobile_device === true && index % 2 === 0)) {
      move_distance -= 8;
    } else if (
      current === total_steps ||
      (mobile_device === true && index % 2 === 1)
    ) {
      move_distance += 8;
    }

    if (mobile_device) {
      vertical_level = parseInt(index / 2, 10);
      vertical_level = vertical_level * 38;
    }
    var movingTabStyle = {
      width: step_width,
      transform:
        "translate3d(" + move_distance + "px, " + vertical_level + "px, 0)",
      transition: "all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)"
    };
    this.setState({ movingTabStyle: movingTabStyle });
  }

  registro = (classes, data, key) => {
    return (
        <MenuItem
          key={key}
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected
          }}
          value={data}
        >
          {data}
        </MenuItem>
      )
  }

  render() {
    const { classes, 
            type,
            subtitle2, 
            color, 
            steps, 
            nextStatuses, 
            statusCampaign, 
            handleChangeStatus
            } = this.props;
    return (
      <div className={classes.wizardContainer} ref="wizard">
      <GridContainer justify="center">
        <GridItem xs={12} lg={12}>
          <Card className={classes.card}>
            <CardBody>
              <GridContainer justify="space-between">
                <GridItem xs={12} sm={12} className={classes.alignCenter}>
                    {this.props.title}
                </GridItem>
                <GridItem xs={12} sm={3} className={classes.alignCenter}>
                <GridContainer justify="space-between">
                  <GridItem xs={12} sm={6}>
                    {type}
                  </GridItem>
                  <GridItem xs={12} sm={6} className={classes.alignCenter}>
                    {subtitle2}
                  </GridItem>
                  <GridItem xs={12} sm={12} className={classes.alignCenter}>
                  <AutomaticAdjustmentInvestment />
                  </GridItem>
                  <GridItem xs={12} sm={12} className={classes.alignCenter}>
                  {this.props.productPaymentDescription}
                  </GridItem>
                </GridContainer>
                <br/>
                
                <br/>
                
                </GridItem>
                
                <GridItem xs={12} sm={3} className={classes.alignCenter}>
                  <br/>
                  <FormControl className={`${classes.selectFormControl} ${classes.fullWidth50}` }>
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                      >
                        {this.props.tags.campaignType}
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        value={statusCampaign}
                        onChange={handleChangeStatus}
                        inputProps={{
                          name: "type",
                          id: "simple-select"
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classes.selectMenuItem
                          }}
                        >
                          {this.props.tags.campaignStatus}
                        </MenuItem>
                        {
                          nextStatuses.map((item, index) => (
                            this.registro(classes, item, index)
                        ))
                        }
                      </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={2} className={classes.alignCenter}>
                  <br/>
                  <h5 className={classes.subtitle}>
                  {this.props.tags.influencersScope}
                  </h5>
                  <InfluencersScopeValue />
                </GridItem>
                <GridItem xs={12} sm={2} className={classes.alignCenter}>
                  <br/>
                  <h5 className={classes.subtitle}>
                  {this.props.tags.engagmentScope}
                  </h5>
                  <InfluencedsScopeValue />
                </GridItem>
                <GridItem xs={12} sm={2} className={classes.alignCenter}>
                  <br/>
                  <div style={{ width: '110px' }}>
                  <InvestmentRequired/>   
                  </div>
                  
                </GridItem>
                <GridItem xs={12} sm={12} className={classes.alignCenter} >
                  <h5 color="red" className={classes.subtitle + " " + classes.formCategory}>
                      {this.props.validateStatus}
                  </h5>
                </GridItem>
              </GridContainer>
            </CardBody>
            <GridContainer justify="stretch" >
              
                <GridItem xs={10} sm={3} className={classes.alignCenter}>
                  {this.props.customAdPriceCheck}
                  {' '+ this.props.customAdPriceTag}
                </GridItem>
                <GridItem xs={5} sm={3} className={classes.align}> 
                  <div style={{ width: '110px' }}>
                    {this.props.customAdPrice}
                  </div> 
                </GridItem> 
                <GridItem xs={5} sm={3} className={classes.align}>
                  <div style={{ width: '110px' }}>
                    {this.props.customInsightMultiplier}
                  </div>
                </GridItem>     
              
            </GridContainer>
            <div className={classes.wizardNavigation}>
              <ul className={classes.nav}>
                {steps.map((prop, key) => {
                  return (
                    <li
                      className={classes.steps}
                      key={key}
                      style={{ width: this.state.width }}
                    >
                      <a
                        className={classes.stepsAnchor}
                        onClick={() => this.navigationStepChange(key)}
                      >
                        {prop.stepName}
                      </a>
                    </li>
                  );
                })}
              </ul>
              <div
                className={classes.movingTab + " " + classes[color]}
                style={this.state.movingTabStyle}
              >
                {steps[this.state.currentStep].stepName}
              </div>
            </div>
            <div className={classes.content}>
              {steps.map((prop, key) => {
                const stepContentClasses = cx({
                  [classes.stepContentActive]: this.state.currentStep === key,
                  [classes.stepContent]: this.state.currentStep !== key
                });
                return (
                  <div className={stepContentClasses} key={key}>
                    <prop.stepComponent
                      innerRef={node => (this[prop.stepId] = node)}
                      allStates={this.state.allStates}
                    />
                  </div>
                );
              })}
            </div>
            <div className={classes.footer}>
              <GridContainer justify="space-between">
                      <GridItem xs={12} sm={4} className={classes.alignCenter}>
                        <div className={classes.left}>
                          {this.state.previousButton ? (
                            <Button
                              className={this.props.previousButtonClasses}
                              onClick={() => this.previousButtonClick()}
                            >
                              {this.props.tags.previous}
                            </Button>
                          ) : null}
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={4} className={classes.alignCenter}>
                      <div>
                        <Button
                          color="rose"
                          className={this.finishButtonClasses}
                          onClick={() => this.finishButtonClick()}
                        >
                          {this.props.tags.save}
                        </Button>
                      </div>
                      </GridItem>
                      <GridItem xs={12} sm={4} className={classes.alignCenter}>
                      <div className={classes.right}>
                        {this.state.nextButton ? (
                          <Button
                            color="rose"
                            className={this.props.nextButtonClasses}
                            onClick={() => this.nextButtonClick()}
                          >
                            {this.props.tags.save}
                          </Button>
                        ) : null}
                        </div>
                      </GridItem>
              </GridContainer>
              <div className={classes.clearfix} />
            </div>
          </Card>
        </GridItem>
      </GridContainer>
      </div>
    );
  }
}

Wizard.defaultProps = {
  classes: null,
  steps: null,
  color: "rose",
  title: "Here should go your title",
  subtitle1: "And this would be your subtitle",
  subtitle2: "And this would be your subtitle2",
  subtitle3: "And this would be your subtitle3",
  status: "status",
  previousButtonText: "Previous",
  previousButtonClasses: "",
  nextButtonClasses: "",
  nextButtonText: "Next",
  finishButtonClasses: "",
  finishButtonText: "Savee",
  finishButtonClick: null,
  validate: false,
};

Wizard.propTypes = {
  classes: PropTypes.object.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      stepName: PropTypes.string.isRequired,
      stepComponent: PropTypes.func.isRequired,
      stepId: PropTypes.string.isRequired
    })
  ).isRequired,
  color: PropTypes.oneOf([
    "primary",
    "warning",
    "danger",
    "success",
    "info",
    "rose"
  ]),
  title: PropTypes.object,
  subtitle1: PropTypes.string,
  subtitle2: PropTypes.object,
  subtitle3: PropTypes.string,
  status: PropTypes.string,
  previousButtonClasses: PropTypes.string,
  previousButtonText: PropTypes.string,
  nextButtonClasses: PropTypes.string,
  nextButtonText: PropTypes.string,
  finishButtonClasses: PropTypes.string,
  finishButtonText: PropTypes.string,
  finishButtonClick: PropTypes.func,
  validate: PropTypes.bool
};

export default withStyles(theme)(Wizard);
